import React, { useState } from 'react'
import selectArrow from '../../../data/icons/select-arrow.png'
import {
  ErrorMessage,
  Form,
  InputsWrapper,
  LabelWrapper,
  LeftBox,
  Option,
  RightBox,
  Selector,
  StyledInput,
  StyledTextarea,
  SubmitButton,
  SuccessMessage,
  Title,
} from './styles'

const ContactForm = () => {
  const [errMsg, setErrMsg] = useState({
    name: false,
    mail: false,
    message: false,
    events: false,
  })
  const [dataForm, setDataForm] = useState({
    name: '',
    mail: '',
    phone: '',
    subject: '',
    message: '',
    type: 'Particulier',
    events: '',
  })

  const [success, setSuccess] = useState(false)

  const handleChange = e => {
    const { value, name } = e.target
    setDataForm({
      ...dataForm,
      [name]: value,
    })
  }

  const validateData = () => {
    const { name, message, mail, events, type } = dataForm

    function validateEmail (mail) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(mail).toLowerCase())
    }

    console.log(type, events)

    setErrMsg({
      name: name.length === 0,
      mail: !validateEmail(mail),
      message: message.length === 0,
      events: type === 'Professionnel' && events.length === 0,
    })

    if (name.length < 1) {
      return false
    } else if (!validateEmail(mail)) {
      return false
    } else if (type === 'Professionnel' && events === '') {
      return false
    } else return message.length >= 1
  }

  const sendMsg = e => {
    console.log('send')
    e.preventDefault()
    const isValid = validateData()

    const { name, mail, message, subject, phone, type, events } = dataForm

    if (isValid) {
      const payload = { name, mail, message, phone, subject, type, events }
      fetch('https://formcarry.com/s/YN6WHvLzxu', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then(res => {
          // console.log('sended', res.ok)
          if (res.ok) {
            setSuccess(true)
            setDataForm({
              name: '',
              mail: '',
              message: '',
              phone: '',
              subject: '',
              type: '',
              events: '',
            })
            setTimeout(() => setSuccess(false), 5000)
          }
        })
        .catch(err => {
          // console.log("not send", err);
        })
    }
  }

  return (
    <>
      <Title id='contact'>Nous écrire</Title>
      <div style={{ height: '2em' }}>
        {errMsg.name || errMsg.mail || errMsg.message || errMsg.events ? (
          <ErrorMessage>
            Veuillez remplir correctement les champs en rouge !!!
          </ErrorMessage>
        ) : null}
      </div>
      <Form onSubmit={sendMsg}>
        <InputsWrapper>
          <LeftBox>
            <LabelWrapper>
              <p>Vous etes un</p>
              <Selector
                name='type'
                onChange={e => handleChange(e)}
                arrow={selectArrow}
              >
                <Option>Particulier</Option>
                <Option>Professionnel</Option>
              </Selector>
            </LabelWrapper>

            <LabelWrapper>
              <p>
                Type d'événement
                <span style={{ fontSize: '0.7em' }}> (Professionnel)</span>
              </p>
              <Selector
                error={errMsg.events}
                defaultValue={'DEFAULT'}
                disabled={dataForm.type === 'Particulier'}
                name='events'
                onChange={e => handleChange(e)}
                arrow={selectArrow}
              >
                <Option value='DEFAULT' disabled>
                  Sélection d'un événement
                </Option>
                <Option>Privatisation</Option>
                <Option>Cathering</Option>
                <Option>Cohésion d'équipe </Option>
                <Option>Autre</Option>
              </Selector>
            </LabelWrapper>

            <LabelWrapper>
              <p>Nom*</p>
              <StyledInput
                name='name'
                value={dataForm.name}
                type='text'
                onChange={handleChange}
                error={errMsg.name}
              />
            </LabelWrapper>
            <LabelWrapper>
              <p>Adresse éléctronique*</p>
              <StyledInput
                name='mail'
                value={dataForm.mail}
                type='mail'
                onChange={handleChange}
                error={errMsg.mail}
              />
            </LabelWrapper>
            <LabelWrapper>
              <p>Téléphone</p>
              <StyledInput
                name='phone'
                size='10'
                value={dataForm.phone}
                type='tel'
                onChange={handleChange}
              />
            </LabelWrapper>
            <LabelWrapper>
              <p>Sujet</p>
              <StyledInput
                name='subject'
                value={dataForm.subject}
                type='text'
                onChange={handleChange}
              />
            </LabelWrapper>
          </LeftBox>
          <RightBox>
            <LabelWrapper>
              <p>Message*</p>
              <StyledTextarea
                name='message'
                value={dataForm.message}
                onChange={handleChange}
                error={errMsg.message}
              />
            </LabelWrapper>
          </RightBox>
        </InputsWrapper>
        <SubmitButton type='submit' />
        <div style={{ height: '3em', marginTop: '1em' }}>
          {success ? (
            <SuccessMessage>Votre message a bien été envoyer</SuccessMessage>
          ) : null}
        </div>
      </Form>
    </>
  )
}

export default ContactForm
