import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { breakpoints } from '../../../utils/styles'

const ContactHeader = () => {
  const images = useStaticQuery(graphql`
    query {
      background: file(
        relativePath: { eq: "images/contact/background-header.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      coffee: file(
        relativePath: { eq: "images/contact/contact-header-img.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container id='block2' img={images.background.childImageSharp.fluid.src}>
      <ImgWrapper>
        <CoffeeImg fluid={images.coffee.childImageSharp.fluid} />
      </ImgWrapper>
      <ContentWrapper>
        <Title>Nous contacter</Title>
        <Text>
          Dans l’attente de vous rencontrer au sein de nos points de vente, nous restons à votre disposition pour tout renseignement au sujet de nos prestations et de nos produits
        </Text>
        <Button href='#contact'>Nous écrire</Button>
      </ContentWrapper>
    </Container>
  )
}

export default ContactHeader

export const Container = styled.div`
  position: relative;
  height: 45em;
  max-width: 1100px;
  margin: 5em auto;
  background-image: url('${props => props.img}') ;
  background-repeat: no-repeat;
  background-size: 80% ;
  background-position: 83% 94%;

  @media (max-width: ${breakpoints.xl}px) {
    background-size: 75% ;
    background-position: 50% 70%;
    margin: 5em 6em;
  }

  @media (max-width: ${breakpoints.l}px) {
    margin: 5em auto;
    background-image: none;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-top: 1em;
    height: 33em;
    width: 95%;
  }
`

export const ImgWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  width: 40em;
  margin: 0 0 0 3em;

  @media (max-width: ${breakpoints.l}px) {
    margin: 0 auto;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    margin-bottom: 1em;
  }
`

export const CoffeeImg = styled(Img)`
  border-radius: 20px;
`

export const ContentWrapper = styled.div`
  position: absolute;
  align-self: flex-end;
  width: 30em;
  height: 17em;
  background-color: #12282e;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1em 2em;
  right: 2em;
  top: 7em;
  color: white;
  font-size: 0.9em;
  font-family: 'Avenir Light', serif;
  border-radius: 20px;

  @media (max-width: ${breakpoints.l}px) {
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 27em;
  }

  @media (max-width: ${breakpoints.s}px) {
    top: unset;
    padding: 1em;
    margin: 0;
    width: fit-content;
    height: fit-content;
  }
`

export const Title = styled.p`
  text-align: center;
  margin: 1em 0 0 0;
  color: #eed7b8;
  font-size: 2.7em;
  font-family: 'Krona One', sans-serif;
  font-weight: 400;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 2em;
    margin-bottom: 1em;
  }
`

export const Text = styled.p`
  text-align: center;
  font-size: 1.1em;
  margin-bottom: 4em;

  @media (max-width: ${breakpoints.s}px) {
    margin: 0 0 3em 0;
    text-align: center;
  }
`

export const Button = styled.a`
  position: absolute;
  border: 2px solid transparent;
  background-color: #eed7b8;
  border-radius: 30px;
  width: 55%;
  height: 1.9em;
  color: #2b5c6b;
  font-size: 1.3em;
  font-family: 'Krona One', serif;
  bottom: -1.2em;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  text-align: center;
  line-height: 1.9;

  @media (hover: hover) {
    :hover {
      background-color: #2b5c6b;
      color: #eed7b8;
      cursor: pointer;
    }
  }
`
