import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'

export const Title = styled.p`
  text-align: center;
  font-size: 2.8em;
  color: #2b5c6b;
  margin: 2em 0 0.5em 0;
`

export const ErrorMessage = styled.p`
  background-color: #ff5757;
  color: white;
  width: fit-content;
  padding: 0.4em 1em;
  border-radius: 40px;
  font-size: 0.9em;
  margin: 0 auto;
  font-family: 'Avenir Light', sans-serif;

  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;

  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    width: 90%;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const Form = styled.form`
  max-width: 900px;
  width: 80%;
  margin: 1em auto 2em auto;

  @media (max-width: ${breakpoints.l}px) {
    width: 90%;
  }
`

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: auto;
  margin-bottom: 2em;

  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
    flex-direction: column;
    height: fit-content;
  }
`

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 48%;
  height: 100%;

  @media (max-width: ${breakpoints.l}px) {
    width: 45%;
  }

  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
    margin-top: 1em;
  }
`

export const RightBox = styled.div`
  width: 48%;

  @media (max-width: ${breakpoints.l}px) {
    width: 45%;
  }

  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }
`

export const LabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.5em;

  p {
    margin: 0 0 0 1.6em;
    color: #2b5c6b;
  }

  @media (max-width: ${breakpoints.m}px) {
    margin-bottom: 1em;
  }
`

export const StyledInput = styled.input`
  height: 2.5em;
  border-radius: 40px;
  border: 2px solid ${props => (props.error ? '#ff5757' : '#2b5c6b')};
  padding-left: 1.4em;
  font-size: 1.1em;
  outline: none;
`

export const Selector = styled.select`
  border-radius: 40px;
  height: 3.15em;
  padding: 0 1em 0 1.4em;
  width: auto;
  font-family: 'Krona One',serif;
  font-size: 1em;
  color: #2b5c6b;
  font-weight: 400;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('${props => props.arrow}');
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 20px;
  border: 2px solid ${props => (props.error ? '#ff5757' : '#2b5c6b')};


  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`

export const Option = styled.option`
  margin-bottom: 5em;
`

export const StyledTextarea = styled.textarea`
  height: 25.2em;
  padding: 1.4em;
  font-size: 1.2em;
  border-radius: 30px;
  resize: none;
  border: 2px solid ${props => (props.error ? '#ff5757' : '#2b5c6b')};
  outline: none;

  p {
    margin: 0 0 0 1.6em;
    color: #2b5c6b;
  }
`

export const SubmitButton = styled.input`
  display: block;
  background-color: #eed7b8;
  border-radius: 40px;
  border: 1px solid transparent;
  color: #2b5c6b;
  margin: 0 auto;
  font-size: 1em;
  width: 10em;
  height: 2.5em;
  font-family: 'Krona One', serif;
  outline: none;

  @media (max-width: ${breakpoints.s}px) {
    width: 60%;
    height: 2.6em;
    font-size: 1.2em;
  }

  :hover {
    cursor: pointer;
    background-color: #2b5c6b;
    color: white;
  }
`

export const SuccessMessage = styled.p`
  background-color: #00e6a8;
  color: white;
  width: fit-content;
  padding: 0.4em 1em;
  border-radius: 40px;
  font-size: 0.9em;
  margin: 0 auto;
  font-family: 'Avenir Light', serif;

  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
