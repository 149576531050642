import React from 'react'
import SEO from './../components/seo'
import ContactForm from '../components/Contact/ContactForm'
import Map from './../components/Contact/Map'
import ContactHeader from '../components/Contact/Header'
import HoraireComponent from '../components/Contact/Horaire'
import NewHoraire from '../components/Contact/NewHoraire'

const Contact = () => {
  return (
    <div>
      <SEO title="contact" description="Page de contact" />
      <ContactHeader />
      <Map />

      <NewHoraire />
      <ContactForm />
    </div>
  )
}

export default Contact
