import React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { breakpoints } from '../../../utils/styles'

const Map = () => {
  const image = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }

        background: file(
            relativePath: { eq: "images/contact/background-header.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
  `)

  return (
    <>
      <MapBox>
        <TitleButton>
          1, rue Auguste Blanqui | 94200 Ivry-sur-Seine
        </TitleButton>
        <TitleButtonMobile>
          1, rue Auguste Blanqui <br /> 94200 Ivry-sur-Seine
        </TitleButtonMobile>
        <GoogleMap
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1313.7391927018846!2d2.386577658243252!3d48.81093319482072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e673af0e3e0c6f%3A0x59eaf6837c4fb192!2sJh%20and%20co!5e0!3m2!1sfr!2sfr!4v1606961774240!5m2!1sfr!2sfr'
          frameborder='0'
          allowfullscreen=''
          aria-hidden='false'
          tabindex='0'
        />
        <Logo fixed={image.logo.childImageSharp.fixed} alt='jh&co' />
        <BottomImagesBox>
          <Image fluid={image.background.childImageSharp.fluid} />
        </BottomImagesBox>
      </MapBox>
    </>
  )
}

export default Map

export const MapBox = styled.div`
  position: relative;
  width: 80%;
  max-width: 1200px;
  height: 32em;
  margin: 5em auto 8em auto;

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`

export const TitleButtonMobile = styled.p`
  text-align: center;
  background-color: #1c2c32;
  border-radius: 50px;
  line-height: 2;
  height: 4em;
  width: 90%;
  font-size: 1em;
  color: #eed7b8;
  left: 0;
  right: 0;
  margin: 0 auto 1em;

  @media (min-width: ${breakpoints.s}px) {
    display: none;
  }
`

export const TitleButton = styled.p`
  position: absolute;
  text-align: center;
  top: -2em;
  background-color: #1c2c32;
  border-radius: 50px;
  height: 4em;
  line-height: 4;
  width: 34em;
  font-size: 1em;
  color: #eed7b8;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2000;

  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`

export const GoogleMap = styled.iframe`
  width: 100%;
  height: 25em;
  border: none;
  z-index: 200;
  position: relative;
`

export const BottomImagesBox = styled.div`
  margin-top: -140px;
  z-index: 0;
`

export const Logo = styled(Img)`
  position: absolute;
  bottom: 5em;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2000;
`

export const Image = styled(Img)`
  max-width: 75%;
  width: 35em;
  margin: 0 auto;

  @media (max-width: ${breakpoints.s}px) {
    max-width: 85%;
    width: 20em;
  }
`