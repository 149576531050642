import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

import { breakpoints } from '../../../utils/styles'
import { graphql, useStaticQuery } from 'gatsby'

import coffeeShopIcon from './../../../data/icons/contact/coffee-shop.png'
import truckIcon from './../../../data/icons/contact/truck.png'

const HoraireComponent = () => {
  const image = useStaticQuery(graphql`
    query {
      background: file(
        relativePath: { eq: "images/contact/background-header.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Title>Horaires d’ouverture</Title>
      <ButtonInfo shop>
        <Icon src={coffeeShopIcon} alt=''/>
        <RightBox>
          <RightBoxTitle>
            Coffee Shop <Space />(Ivry sur Seine)
          </RightBoxTitle>
          <RightBoxText>
            Du Lundi au Vendredi de <span>8h00 à 18h00 </span>
            <br /> & le Samedi de <span>10h00 à 18h00</span>
          </RightBoxText>
        </RightBox>
      </ButtonInfo>

      <ButtonInfo>
          <Icon src={truckIcon} alt=''/>
        <RightBox>
          <RightBoxTitle style={{ marginBottom: '0.2em' }}>
            Coﬀee Truck <Space />(La Défense)
          </RightBoxTitle>
          <RightBoxTitle>
            Adresse du spot
          </RightBoxTitle>
          <RightBoxText>
            Du Lundi au Vendredi de <span>7h00 à 15h00</span>
          </RightBoxText>
        </RightBox>
      </ButtonInfo>
      <Image fluid={image.background.childImageSharp.fluid} />
    </Container>
  )
}

export default HoraireComponent

export const Space = styled.br`
  display: none;

  @media (max-width: ${breakpoints.s}px) {
    display: block;
  }
`

export const Container = styled.div`
  display: block;
  max-width: 45em;
  width: 60%;
  margin: 2em auto;
  text-align: center;

  @media (max-width: ${breakpoints.xl}px) {
    margin-top: 12em;
    width: 95%;
  }
`

export const Title = styled.p`
  font-size: 2em;
  color: #2b5c6b;
`

export const ButtonInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color:${props => props.shop ? '#2b5c6b' : '#12282e'};
  color: white;
  padding: 0.5em;
  font-family: 'Avenir Light', serif;
  font-size: 1.7em;
  border-radius: 100px;
  margin-bottom: ${props => props.shop ? '1.5em' : ''};

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    padding: 2em 0.5em;
    border-radius: 30px;
    justify-content: space-between;
  }
`
export const RightBox = styled.div`
  text-align: start;
  margin-left: 1em;

  @media (max-width: ${breakpoints.s}px) {
    margin-left: 0;
    text-align: center;
  }
`

export const RightBoxTitle = styled.p`
  color: #eed7b8;
  margin-top: 0;
  font-family: "Krona One",serif;
  font-size: 0.8em;
`

export const RightBoxText = styled.p`
  margin-bottom: 0;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 0.9em;
  }
  
  span {
    font-weight: bold;
  }
`

export const Icon = styled.img`
  width: 5em;
  height: 5em;

  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 1em;
  }
`

export const Image = styled(Img)`
  max-width: 75%;
  width: 37em;
  margin: 0 auto;

  @media (max-width: ${breakpoints.s}px) {
    max-width: 65%;
    width: 17em;
  }
`
