import React from 'react'
import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'

import truckIcon from './../../../data/images/contact/truck-icon.png'
import phoneIcon from './../../../data/images/contact/phone-icon.png'
import shopIcon from './../../../data/images/contact/shop-icon.png'
import mailIcon from './../../../data/images/contact/mail-icon.png'

const NewHoraire = () => {
  return (
    <Container>
      <RowBox>
        <RowBoxTitle>Nous joindre</RowBoxTitle>
        <BoxContainer>
          <BoxIcon>
            <Icon style={{ width: '2.5em' }} src={phoneIcon} />
          </BoxIcon>
          <BoxContent>
            <ContentTextTitle>Numéro de téléphone</ContentTextTitle>
            <PhoneNumber href='tel:0620142775'>06. 20. 14. 27. 75</PhoneNumber>
          </BoxContent>
        </BoxContainer>

        <Separator />

        <BoxContainer>
          <BoxIcon>
            <Icon style={{ width: '2.9em' }} src={mailIcon} />
          </BoxIcon>
          <BoxContent>
            <ContentTextTitle>Mail</ContentTextTitle>
            <ContentText><a href='mailto: someone@example.com'>contact@<span>jhnco.fr</span></a></ContentText>
          </BoxContent>
        </BoxContainer>
      </RowBox>

      <RowBox>
        <RowBoxTitle>Horaires d’ouverture</RowBoxTitle>
        <BoxContainer>
          <BoxIcon>
            <Icon style={{ width: '3.3em' }} src={shopIcon} />
          </BoxIcon>
          <BoxContent>
            <ContentTextTitle>Coffee Shop (Ivry sur Seine)</ContentTextTitle>
            <ContentText>Du Lundi au Vendredi de <span>8h00 à 18h00</span></ContentText>
            <ContentText>& le Samedi de <span>10h00 à 18h00</span></ContentText>
          </BoxContent>
        </BoxContainer>

        <Separator />

        <BoxContainer>
          <BoxIcon>
            <Icon style={{ width: '5em' }} src={truckIcon} />
          </BoxIcon>
          <BoxContent>
            <ContentTextTitle>Coﬀee Truck (La Défense)</ContentTextTitle>
            <ContentText>Place de la défense | 92800 Puteaux</ContentText>
            <ContentText>Du Lundi au Vendredi de <span>7h00 à 15h00</span></ContentText>
          </BoxContent>
        </BoxContainer>
      </RowBox>
    </Container>
  )
}

export default NewHoraire

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10em;
  margin: 0 auto;
  color: #2B5C6B;
  max-width: 55em;

  @media (max-width: ${breakpoints.xl}px) {
    padding: 0 5em;
  }

  @media (max-width: ${breakpoints.l}px) {
    align-items: center;
    padding: 0 2em;
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.s}px) {
    padding: 0 1em;
  }
`

export const RowBox = styled.div`
  width: 50%;
  max-width: 30em;

  @media (max-width: ${breakpoints.l}px) {
    width: 90%;
    max-width: 25em;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`

export const RowBoxTitle = styled.p`
  color: #2B5C6B;
  font-size: 1.5em;
  margin-left: 1em;
  margin-bottom: 2em;

  @media (max-width: ${breakpoints.l}px) {
    text-align: left;
  }
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2em;
  height: 4em;
`

export const BoxIcon = styled.div`
  width: 5em;
`

export const Icon = styled.img`
  float: right;
`

export const BoxContent = styled.div`
  margin-left: 1.8em;
`

export const ContentTextTitle = styled.p`
  font-size: .8em;
  margin: 0 0 .3em;
  
`

export const ContentText = styled.p`
  font-size: 1em;
  margin: 0;
  font-family: 'Avenir Light', serif;
  
  a {
    text-decoration: none;
    color: #2B5C6B;
  }
  
  span {
    font-weight: bold;
  }
`

export const PhoneNumber = styled.a`
  font-size: 1.3em;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
  color: #2B5C6B;
`

export const Separator = styled.div`
  border-bottom: 1px solid #2B5C6B;
  margin: 2em 0 2em 7em;
  width: 8em;
`